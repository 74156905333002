<template>
  <div class="error-page text-center my-10">
    <div class="text-center">
        <span class="font-22 primary--text d-block">{{$t('paymentSuccessfully')}}</span>
        <!-- <span class="font-22 primary--text d-block">{{$t('Your payment has completedsuccessfully')}}</span> -->
        <img class="my-4" :src="require('@/assets/images/draw/success.png')" alt="">
    </div>
    <v-btn @click="$router.replace('/profile/shipments')" color="primary"
      ><span class="font-20 font-500">{{$t('goToShipments')}}</span></v-btn
    >
  </div>
</template>

<script>
export default {};
</script>

<style></style>
